import React from 'react';
import { DetailsAndUploadForm } from '../components/Form23/DetailsAndUploadForm';
import { ReportFrame } from '../components/ReportFrame';

export function Chemistry() {
  const tabs = [
    {label: 'Well details', component: DetailsAndUploadForm},
    {label: 'Submit for review', component: () => <div>WIP</div>},
  ]

  return <ReportFrame type='chemistry' tabs={tabs} />;
}
