import { SelectableValue } from '@grafana/data';

export enum ROUTES {
  home = 'home',
  pressure = 'pressure',
  chemistry = 'chemistry',
}

const reviewTags: SelectableValue[] = [
  {
    value: 'reviewed [pass]',
    description: 'Everything looks good.',
    icon: '🟢' // intentionally not imgUrl so it won't render in the select
  },
  {
    value: 'reviewed [fail]',
    description: 'This needs more attention.',
    icon: '🔴' // intentionally not imgUrl so it won't render in the select
  },
]

export const TAGS = {
  pressure: [ // put these in priority of lifecycle. earlier first!
    {
      value: 'in progress', // ultimately limit these to Editors or something higher using an access key?
      description: 'The test is currently being performed, or is still awaiting data before it can be reviewed.'
    },
    {
      value: 'ready for review',
      description: 'The test is is complete. It has everything needed to be reviewed.'
    },
    ...reviewTags
  ],
  chemistry: [
    {
      value: 'samples collected',
      description: 'The field work is done, but nothing has been sent to the lab yet.'
    },
    {
      value: 'sent to lab',
      description: 'Samples have been sent to the lab and we are now awaiting the results.'
    },
    {
      value: 'ready for review',
      description: 'The lab results have been uploaded and can now be reviewed.'
    },
    ...reviewTags
  ]
}

export const STATES = [
  "Alaska",
  "Alabama",
  "Arkansas",
  "American Samoa",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "Washington DC",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Iowa",
  "Idaho",
  "Illinois",
  "Indiana",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Massachusetts",
  "Maryland",
  "Maine",
  "Michigan",
  "Minnesota",
  "Missouri",
  "Northern Mariana Islands",
  "Mississippi",
  "Montana",
  "North Carolina",
  "North Dakota",
  "Nebraska",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "Nevada",
  "New York",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Virginia",
  "U.S. Virgin Islands",
  "Vermont",
  "Washington",
  "Wisconsin",
  "West Virginia",
  "Wyoming"
]
