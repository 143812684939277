import React, { useState } from 'react';
import { getBackendSrv, PluginPage } from "@grafana/runtime";
import { css } from '@emotion/css';
import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { useStyles2, Dropdown, Button, Menu, Icon, FilterInput, RadioButtonGroup, InteractiveTable, MultiSelect, Stack, LinkButton } from '@grafana/ui';
import { BASE_URLS } from 'shared/constants';
import { ROUTES, TAGS } from '../constants';
import { useAsync } from "react-use";
import { generateOptions } from 'shared/utils';

const CreateNewButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  
  const menu =
    <Menu>
      <Menu.Item label="Pressure test" url={`${BASE_URLS.BHEAD}/${ROUTES.pressure}`} />
      <Menu.Item label="Chemistry test" url={`${BASE_URLS.BHEAD}/${ROUTES.chemistry}`} />
    </Menu>;

  return (
    <Dropdown overlay={menu} onVisibleChange={setIsOpen}>
      <Button>
        New
        <Icon name={isOpen ? 'angle-up' : 'angle-down'} />
      </Button>
    </Dropdown>
  )
}

export function Home() {
  const s = useStyles2(getStyles);

  const [searchQuery, setSearchQuery] = useState('');
  const [viewMode, setViewMode] = useState('list');
  const viewModeOptions = [{
    description: 'View as list',
    value: 'list',
    icon: 'list-ul'
  }, {
    description: 'Group by well',
    value: 'grouped',
    icon: 'map-marker'
  }];

  const [statusFilter, setStatusFilter] = useState<Array<SelectableValue<string>>>([]);

  const { error, loading, value } = useAsync(() => {
    const backendSrv = getBackendSrv();

    return Promise.all([
      backendSrv.get(`${BASE_URLS.API}/reports?count=9999`),
    ]);
  });

  // if (error || !value) {
  //   return (
  //     <div data-testid={testIds.pageOne.container}>
  //       <span>Error: {error?.message}</span>
  //     </div>
  //   );
  // }

  const [response] = value || [{data: {reports: []}}];

  // set up the table cols. the rows are already ready to go in the reports array.
  const cols = React.useMemo(() => {
    const linkCol = {id: 'link', cell: (data) => {
      return (
        <LinkButton href={`${BASE_URLS.BHEAD}/${ROUTES[data.row.values.type]}/${data.row.id}`} size="sm" icon="edit">
          Open
        </LinkButton>
      )
    }}

    const groupedCols = [ // the minimum description of the record; when grouped this is all we see
      {id: 'well_name', header: 'Well name', sortType: 'string'},
      {id: 'api_number', header: 'API number', sortType: 'string'},
    ]

    const groupedListCols = [ // visible as a sublist when grouped by well/api, so we don't need to duplicate those cols.
      {id: 'datetimeObj', header: 'Date', sortType: 'datetime', cell: data => data.row.original.datetime},
      {id: 'type', header: 'Type of test', sortType: 'string'},
      {id: 'status', header: 'Status', sortType: 'string'},
      {id: 'data_collector', header: 'Data collected by', sortType: 'string'},
      {id: 'uploader', header: 'Uploaded by', sortType: 'string'},
    ]

    const listCols = [ // for when showing every item (i.e. nothing grouped, just the full list)
      ...groupedCols,
      ...groupedListCols
    ]

    return {grouped: groupedCols, groupedList: [linkCol, ...groupedListCols], list: [linkCol, ...listCols]}
  }, [])
  const data = React.useMemo(() => {
    const list = response.data.reports
      .filter(report => (report.well_name + report.api_number).toLowerCase().includes(searchQuery.toLowerCase())) // filter by search query
      .filter(report => statusFilter.length === 0 || statusFilter.map(status => status.value?.toLowerCase()).includes(report.status.toLowerCase())) // filter by status
      .map(report => { // map and format dates
        const datetimeObj = new Date(report.datetime)
        return {...report, datetimeObj, datetime: datetimeObj.toLocaleDateString()}
      })
    const grouped = Object.values(list.reduce((outObj, row) => {
      // group rows using well name and api number as a compound key (stripping leading/trailing whitespace)
      const key = `${row.well_name.trim()}:${row.api_number.trim()}`;
      outObj[key] = {
        id: key,
        well_name: row.well_name,
        api_number: row.api_number,
      }
      return outObj
    }, {}))

    return {list, grouped}
  }, [response.data, searchQuery, statusFilter])

  const renderGroupList = (groupRow) => { // returns a table nested under a grouped row. only fires when row is expanded
    const groupListData = data.list.filter(row => row.well_name === groupRow.well_name && row.api_number === groupRow.api_number)
    return <div style={{padding: '0px 8px 8px 20px'}}>
      <InteractiveTable columns={cols.groupedList} data={groupListData} getRowId={r => r.id} pageSize={20} className={s.subtable}/>
    </div>
  }

  return (
    <PluginPage actions={<CreateNewButton/>}>
      <div>
        <Stack gap={2} alignItems="center">
          <FilterInput
            placeholder='Search for test reports by well name or API number'
            value={searchQuery}
            escapeRegex={false}
            onChange={(event) => setSearchQuery(event)}
          />
          <div style={{minWidth: '300px', maxWidth: '550px'}}>
            <MultiSelect
              options={generateOptions([...new Set([...TAGS.pressure, ...TAGS.chemistry].map(tag => tag.value))])}
              value={statusFilter}
              onChange={setStatusFilter}
              noMultiValueWrap={true}
              placeholder='Filter by status'
              prefix={<Icon name="tag-alt" />}
            />
          </div>
          <RadioButtonGroup
            options={viewModeOptions}
            value={viewMode}
            onChange={event => setViewMode(event)}
          />
        </Stack>
        {viewMode === 'list' ? (
          <InteractiveTable columns={cols.list} data={data.list} getRowId={r => r.id} pageSize={20} className={s.marginTop}/>
        ) : (
          <InteractiveTable
            columns={cols.grouped}
            data={data.grouped}
            getRowId={r => r.id}
            pageSize={20}
            className={s.marginTop}
            renderExpandedRow={renderGroupList}
          />
        )}
        {loading && <div><span>Still loading...</span></div>}
      </div>
    </PluginPage>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  marginTop: css`
    margin-top: ${theme.spacing(2)};
  `,
  subtable: css({
    'td::before': {
      content: 'none'
    }
  })
});
