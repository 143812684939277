import { DatePickerWithInput, Input, RadioButtonGroup, Select } from '@grafana/ui';
import { STATES } from '../../constants';
import { generateOptions } from 'shared/utils';

export const wellDetailsFields = [ // render out to form item 13 just using json
  {
    id: 'operatorNumber',
    label: 'Operator number',
    tooltip: 'State of Colorado Oil and Gas Conservation Commission operator number.',
    component: Input,
  },
  {
    id: 'operatorName',
    label: 'Operator name',
    component: Input,
  },
  {
    id: 'blmLease',
    label: 'BLM lease number',
    component: Input,
  },
  {
    id: 'apiNumber',
    label: 'API number',
    component: Input,
  },
  {
    id: 'multipleCompletion',
    label: 'Multiple completion?',
    component: RadioButtonGroup,
    // these options get passed to the component.
    options: generateOptions(['yes', 'no']),
    fullWidth: true
  },
  {
    id: 'wellName',
    label: 'Well name and number',
    component: Input,
  },
  {
    id: 'state',
    label: 'State',
    component: Select,
    // these options get passed to the component.
    options: generateOptions(STATES),
  },
  {
    id: 'county',
    label: 'County',
    component: Input,
  },
  {
    id: 'location',
    label: 'Location',
    tooltip: 'QtrQtr, Sec, Twp, Rng, Meridian.',
    component: Input,
  },
  {
    id: 'fieldName',
    label: 'Field name',
    component: Input,
  },
  {
    id: 'minerals',
    label: 'Minerals',
    component: Select,
    // these options get passed to the component.
    options: generateOptions(['N/A', 'fee', 'state', 'federal', 'indian']),
  },
  {
    id: 'date',
    label: 'Date of test',
    component: DatePickerWithInput,
    // these options get passed to the component.
    closeOnSelect: true,
    maxDate: new Date(), // can't select future dates
  },
  {
    id: 'wellStatus',
    label: 'Well status',
    component: Select,
    // these options get passed to the component.
    options: generateOptions(['N/A', 'flowing', 'shut in', 'gas lift', 'pumping', 'injection', 'clock/intermitter', 'plunger lift']),
  },
  {
    id: 'casingStringCount',
    label: 'Number of casing strings',
    component: Select,
    // these options get passed to the component.
    options: generateOptions(['N/A', 'two', 'three', 'liner?']),
  },
];

export const existingPressureFields = [ // 8 basic fields at the bottom of top half
  {
    id: 'tubing',
    label: 'Tubing',
    component: Input,
  },
  {
    id: 'tubingFm',
    label: 'Formation',
    component: Input,
  },
  {
    id: 'tubingAlt',
    label: 'Tubing (alt)',
    component: Input,
  },
  {
    id: 'tubingAltFm',
    label: 'Formation',
    component: Input,
  },
  {
    id: 'prodCasing',
    label: 'Production casing',
    component: Input,
  },
  {
    id: 'prodCasingFm',
    label: 'Formation',
    component: Input,
  },
  {
    id: 'intermediateCasing',
    label: 'Intermediate casing',
    component: Input,
  },
  {
    id: 'surfaceCasing',
    label: 'Surface casing',
    component: Input,
  }
];

export const bradenheadDescFields =[
  {
    id: 'buriedValve',
    label: 'Buried valve?',
    component: RadioButtonGroup,
    // these options get passed to the component.
    options: generateOptions(['yes', 'no']),
    fullWidth: true
  },
  {
    id: 'confirmedOpen',
    label: 'Confirmed open?',
    component: RadioButtonGroup,
    // these options get passed to the component.
    options: generateOptions(['yes', 'no']),
    fullWidth: true
  },
  {
    id: 'sampleTaken',
    label: 'Bradenhead sample taken?',
    component: Select,
    // these options get passed to the component.
    options: generateOptions(['yes', 'no', 'gas', 'liquid']),
  },
  {
    id: 'fluidCharacter',
    label: 'Character of Bradenhead fluid?',
    component: Select,
    // these options get passed to the component.
    options: generateOptions(['clear', 'fresh', 'sulfur', 'salty', 'black', 'other']),
  },
  {
    id: 'fluidDescription',
    label: 'Fluid description',
    tooltip: 'Only necessary if not adequately described by fluid character.',
    component: Input,
  },
]

export const bradenheadTableCols = [
  {
    id: 'bradenheadFlow',
    label: 'Bradenhead flow',
    component: Select,
    // these options get passed to the component.
    options: generateOptions(['no flow', 'continuous', 'down to 0', 'surge', 'whisper']),
  },
  {
    id: 'bradenheadFluid',
    label: 'Bradenhead fluid',
    component: Select,
    // these options get passed to the component.
    options: generateOptions(['none', 'water H2O', 'mud', 'gas', 'vapor', 'liquid hydrocarbon', 'water & mud', 'water & gas', 'water & vapor', 'mud & gas', 'mud & vapor', 'gas & vapor', 'water & liquid hydrocarbon', 'mud & liquid hydrocarbon', 'gas & liquid hydrocarbon', 'vapor & liquid hydrocarbon']),
  },
  {
    id: 'tubingFm',
    label: 'Formation',
    component: Input,
  },
  {
    id: 'tubing',
    label: 'Tubing',
    component: Input,
  },
  {
    id: 'tubingAltFm',
    label: 'Formation (alt)',
    component: Input,
  },
  {
    id: 'tubingAlt',
    label: 'Tubing (alt)',
    component: Input,
  },
  {
    id: 'prodCasing',
    label: 'Production casing',
    component: Input,
  },
  {
    id: 'intermediateCasing',
    label: 'Intermediate casing',
    component: Input,
  },
]

export const bradenheadTableRows = [
  {prefix: '0min_', label: 'Start'},
  {prefix: '5min_', label: '5 mins'},
  {prefix: '10min_', label: '10 mins'},
  {prefix: '15min_', label: '15 mins'},
  {prefix: '20min_', label: '20 mins'},
  {prefix: '25min_', label: '25 mins'},
  {prefix: '30min_', label: '30 mins'},
]

// populate total the table space here, just for the state. this is also done in the component.
const bradenheadTable = bradenheadTableRows.map(({prefix, label}) => {
  return bradenheadTableCols.map(col => {
    return {...col, id: `${prefix}${col.id}`, label: `${label} ${col.label}`}
  })
}).flat()

// init state, computing empty form state from all field schemas
export const masterFields = [wellDetailsFields, existingPressureFields, bradenheadDescFields, bradenheadTable].flat()

export const Form17DataState = masterFields.reduce((obj, field) => {
  obj[field.id] = ''; // Initialize each property with an empty string
  return obj;
}, {});
