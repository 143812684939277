import React from 'react';
import { WellDetailsForm } from '../components/Form17/WellDetailsForm';
import { BradenheadTestForm } from '../components/Form17/BradenheadTestForm';
import { AssessmentForm } from '../components/Form17/AssessmentForm';
import { ReportFrame } from '../components/ReportFrame';

export function Pressure() {
  const tabs = [
    {label: 'Well details', component: WellDetailsForm},
    {label: 'Bradenhead test', component: BradenheadTestForm},
    {label: 'Assessment', component: AssessmentForm},
    {label: 'Submit for review', component: () => <div>WIP</div>},
  ]

  return <ReportFrame type='pressure' tabs={tabs} />;
}
