import React, { useEffect, useState, ComponentType, ChangeEvent } from 'react';
import { useAsync } from "react-use";
import { css } from '@emotion/css';
import { useNavigate, useParams } from 'react-router-dom';
import { GrafanaTheme2, SelectableValue } from '@grafana/data';
import { Button, Tab, TabsBar, useStyles2 } from '@grafana/ui';
import { PluginPage, getBackendSrv, config } from '@grafana/runtime';
import { Form17DataState, masterFields } from './Form17/fields';
import { Form23DataState } from './Form23/fields';
import { ROUTES } from '../constants';
import { BASE_URLS } from 'shared/constants';
import { downloadBlob } from 'shared/utils';

interface TabItem {
  label: string;
  component: ComponentType<any>; // Accept any React component type
  props?: { [key: string]: any }; // Optional props to pass to the component
}

interface ReportFrameProps {
  type: 'pressure' | 'chemistry';
  tabs: TabItem[];
}

export function ReportFrame({type, tabs}: ReportFrameProps) {
  const s = useStyles2(getStyles);

  const pageNav = { // default page nav
    text: `New ${type} test`,
    subTitle: `Fill out the form to create a new ${type} test report.`
  };

  // get URL params and init empty state
  const { id } = useParams<{ id: string }>(); // id is <int:record_id>

  // set the initial report state
  const [reportState, setReportState] = useState({ // TO DO: use sharedTypes.ts
    id: id?.split(':')?.[0] || 'new',
    datetime: null,
    author: null,
    data_collector: config.bootData.user.name, // default to currently authed username
    uploader: null, // set by the server
    uploader_email: null, // set by the server
    api_number: null,
    well_name: null,
    type: type,
    data: type === 'pressure' ? Form17DataState : Form23DataState,
    status: null
  });

  const handleInputChange = (path: string, event: ChangeEvent<HTMLInputElement> | SelectableValue | String | Date | any[]) => {
    // specify the path of property to update, i.e. 'author' or 'data.wellName' and pass pretty much any input type
    // ugh, obnoxious... inputs return an event.target, selects return event.value, but radios just return the value directly
    let value: String | any[];
    if (typeof event === 'object' && 'target' in event) { // it's a ChangeEvent from an input
      value = event.target.value;
    } else if (typeof event === 'object' && 'value' in event) { // it's a SelectableValue from select fields
      value = event.value;
    } else if (event instanceof Date) { // coax to ISO string if it's a date
      value = event.toISOString()
    } else if (event instanceof Array) { // fuck it, might as well just throw more shit on the pile... this is for file uploads in array
      value = event
    } else { // just take the string if it's anything else. explicit typecasting to string to shut typescript up
      value = event.toString();
    }
  
    setReportState((prevState) => {
      // Split the path and reduce it to correctly nest the update
      const keys = path.split('.');
      const lastKey = keys.pop();
      const lastPrevState = keys.reduce((state, key) => state[key] || {}, prevState);
  
      return {
        ...prevState,
        // Use the keys to nest the update
        ...keys.reduceRight((value, key) => ({ [key]: value }), { ...lastPrevState, [lastKey]: value }),
      };
    });
  };

  // state to manage the tabs
  const [tabState, setTabState] = useState(0);

  // if we have an id, use info from the state to render the title
  if (reportState.id !== 'new') {
    // set the page title with the info we have
    pageNav.text = `${reportState.well_name} (${type} test)`
    pageNav.subTitle = `This test was performed on ${new Date(reportState.datetime || '').toLocaleDateString()}.`
  }

  // if we have a record ID, go fetch better data async and update the store
  const { error, loading, value } = useAsync(() => {
    if (reportState.id === 'new') { // if we're creating a new one, there's nothing to fetch now.
      return Promise.resolve(null);
    }
    const backendSrv = getBackendSrv();
    return backendSrv.get(`${BASE_URLS.API}/reports/${reportState.id}`);
  });

  // update the state if we learned anything from the async call.
  useEffect(() => {
    if (value && value?.data?.report) {
      setReportState(value.data.report)
    }
  }, [value]);

  const navigate = useNavigate();
  function saveReport() {
    // update the root state obj with some values from the form
    setReportState((prevState) => {
      const newState = {
        ...prevState,
        ...{ // for genericizing between chem and pressure, we fall back to top-level state vals for these
          datetime: prevState.data?.date || prevState.datetime,
          api_number: reportState.data?.apiNumber || prevState.api_number,
          well_name: reportState.data?.wellName || prevState.well_name,
          // status is already present in the state-- doesn't need to be pulled from the form
        }
      }
      const backendSrv = getBackendSrv();
      backendSrv.post(`${BASE_URLS.API}/reports/${reportState.id}`, newState).then(response => {
        // redirect to new page when complete
        if (reportState.id === 'new') {
          navigate(`${BASE_URLS.BHEAD}/${ROUTES[type]}/${response.data.upsertedRowId}`); // set the proper page URL. does not reload the page.
          newState.id = response.data.upsertedRowId; // change the ID from 'new' to force a rerender of the title
        }
      })
      return newState
    });
  }
  const saveButton = <Button icon="save" onClick={saveReport}>Save</Button>

  function downloadReport() {
    // iterate through master fields (which has the form labels already ordered), and pull form values from the state
    const metadataFields = [
      `Author,${reportState.author}`,
      `Data collector,${reportState.data_collector}`,
      `Uploader,${reportState.uploader}`,
      `Uploader email,${reportState.uploader_email}`
    ]
    const reportFields = masterFields.map(field => `${field.label},${reportState.data[field.id]}`)
    const csvString = [...metadataFields, ...reportFields].join('\n')
    downloadBlob(csvString, `${reportState.well_name} Bradenhead test - ${reportState.datetime}.csv`, 'text/csv;charset=utf-8;')
  }
  const downloadButton = <Button icon="file-download" onClick={downloadReport} variant='secondary'>Download</Button>

  const actions = reportState.id !== 'new' && type === 'pressure' ? [downloadButton, saveButton] : saveButton

  return (
    <PluginPage pageNav={pageNav} actions={actions}>
      <TabsBar>
        {tabs.map((tab, index) => (
          <Tab key={index} label={tab.label} active={tabState === index} onChangeTab={() => setTabState(index)}/>
        ))}
      </TabsBar>
      <div className={s.marginTop}>
        {React.createElement(tabs[tabState].component, {reportState, handleInputChange})}
      </div>
    </PluginPage>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  marginTop: css`
    margin-top: ${theme.spacing(2)};
  `,
});
