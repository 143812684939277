import { DatePickerWithInput, Input, Select } from '@grafana/ui';
import { TAGS } from '../../constants';
import { generateOptions } from 'shared/utils';

export const reportFields = [
  {
    id: 'api_number',
    label: 'API number',
    component: Input,
  },
  {
    id: 'well_name',
    label: 'Well name and number',
    component: Input,
  },
  {
    id: 'datetime',
    label: 'Date of test',
    component: DatePickerWithInput,
    // these options get passed to the component.
    closeOnSelect: true,
    maxDate: new Date(), // can't select future dates
  },
  {
    id: 'data_collector',
    label: 'Data collector',
    tooltip: 'The person who was in the field collecting these samples.',
    component: Input,
  },
  {
    id: 'author',
    label: 'Report author',
    tooltip: 'The person who will sign their name to this report. They probably have a PhD or something.',
    component: Input,
  },
  {
    id: 'status',
    label: 'Status',
    component: Select,
    options: generateOptions(TAGS.chemistry),
  },
];

export interface UploadedItem {
  id: string; // uuid string
  name: string; // the file name
  datetime_uploaded: string; // ISO datetime string, set on the server
}

export const Form23DataState: UploadedItem[] = [];
